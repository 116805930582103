document.addEventListener('DOMContentLoaded', function () {
	const isHomepage = window.location.pathname === '/'; // Check if the user is on the homepage
	const popupKey = 'popupDisplayed'; // Key for localStorage
	const popupTimestampKey = 'popupClosedTimestamp'; // Key for timestamp

	// Function to show popup
	function showPopup() {
		const popup = document.querySelector('.popup');
		if (popup) {
			popup.style.display = 'flex'; // Show the popup
			setTimeout(() => {
				popup.style.opacity = '1';
			}, 300);
		}
	}

	// Function to hide popup and store the timestamp
	function hidePopup() {
		const popup = document.querySelector('.popup');
		if (popup) {
			popup.style.opacity = '0';
			setTimeout(() => {
				popup.style.display = 'none'; // Hide the popup
			}, 300);
		}

		// Set the current timestamp when popup is closed
		const currentTime = new Date().getTime();
		localStorage.setItem(popupTimestampKey, currentTime);
		localStorage.setItem(popupKey, 'true');
	}

	// Check if 24 hours have passed since the last popup
	function shouldShowPopup() {
		const popupDisplayed = localStorage.getItem(popupKey);
		const popupClosedTimestamp = localStorage.getItem(popupTimestampKey);

		// If popup was never shown or more than 24 hours have passed
		if (
			!popupClosedTimestamp ||
			new Date().getTime() - popupClosedTimestamp > 24 * 60 * 60 * 1000
		) {
			return true;
		}

		return false;
	}

	if (isHomepage && shouldShowPopup()) {
		showPopup();
	}

	// Close button functionality
	const closeButton = document.querySelector('.popup--close-btn');
	if (closeButton) {
		closeButton.addEventListener('click', function () {
			hidePopup();
		});
	}
});
